import { HeroesImage } from "./HeroesImage";
import { FormattedMessage } from "react-intl";
import { ShadowButton } from "../buttons/ShadowButton";
import { ReactComponent as OpenSeaIcon } from "../../assets/opensea.svg";
import HeroesBlurredUrl from "../../assets/images/heroes/blurred-bg.png";
import {COINBASE_URL, DISCORD_URL, OPENSEA_LEARN_URL, useMintUrl} from "../../config";
import { DefaultButton } from "../buttons/DefaultButton";
import { Popup } from "../common/Popup";
import { useState } from "react";


export function HeroesTopSection(props: { onHowToOpen: () => void }) {
    const MINT_URL = useMintUrl();

    return <div className="overflow-hidden">
        <div className="px-6 xl:px-0 mx-auto my-6 max-w-full" style={{ maxWidth: "1440px" }}>
            <div className="flex flex-col md:flex-row-reverse">
                <div className="md:w-7/12">
                    <HeroesImage />
                </div>
                <div className="md:w-5/12 relative">
                    <div className="hidden md:block md:absolute h-full top-0" style={{
                        backgroundImage: `url(${HeroesBlurredUrl})`,
                        backgroundSize: `100%`,
                        backgroundRepeat: 'no-repeat',
                        left: `-20%`,
                        width: `120%`
                    }} />
                    <div className="md:absolute w-full left-0 top-0 z-10 flex flex-col md:justify-center" style={{ height: `${9 / 10.7 * 100}%` }}>
                        <div className="text-lg text-light-dark font-bold">
                            MINTED OUT!
                        </div>
                        <div className="text-4xl md:text-7xl text-dark font-bold">
                            <FormattedMessage id="Castle Heroes" />
                        </div>
                        <div className="text-description-regular text-light-dark mt-3 font-sofia-pro">
                            <FormattedMessage id="Sichere dir dein NFT und werde Teil des Mythos!" />
                        </div>
                        <div className="flex flex-col md:flex-row md:items-center mt-4 gap-6">
                            <a href="https://opensea.io/collection/castle-heroes" className="block w-full md:inline md:w-auto">
                                <ShadowButton className="block w-full md:inline md:w-auto">
                                    <div className="flex">
                                        <OpenSeaIcon className="opensea-icon w-6 h-6 mr-2" /> <FormattedMessage id={"Castle Heroes auf Open Sea"} />
                                    </div>
                                </ShadowButton>
                            </a>

                        </div>
                        <a href={DISCORD_URL} >
                            <div className="text-description-regular text-light-dark mt-3 font-sofia-pro">
                            <FormattedMessage id="Tritt der Castle Heroes Community bei!" />
                        </div>
                        </a>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>;
}
