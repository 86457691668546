import { ReactNode } from "react";


export function Popup(props: {children: ReactNode, width?: string, onClose?: ()=>void}) {
    return <>
        <div className="fixed w-full h-full inset-0" 
            style={{
                zIndex: 9998,
                background: `rgba(44,52,74,.8)`
            }} 
            onClick={()=>props.onClose?.()}
        />
        <div className="fixed rounded-xl bg-white overflow-hidden" style={{
            zIndex: 9999,
            left: '50%',
            top: '50%',
            transform: 'translateX(-50%) translateY(-50%)',
            maxWidth: '90%',
            width: props.width||"700px",
            maxHeight: '90vh',
            overflow: "hidden"
        }}>
            <div className="overflow-auto rounded-scrollbar p-10" style={{maxHeight: '90vh'}}>
                {props.children}
            </div>
        </div>
    </>
}