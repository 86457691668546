import FooterUrl from "../../assets/images/footer.png";
import FooterGradUrl from "../../assets/images/footer_grad.png";
import {FormattedMessage} from "react-intl";
import { DiscordButton } from "../buttons/DiscordButton";
import { DISCORD_URL } from "../../config";


export function JoinDiscordSection() {

    return <div className="p-6 md:p-0 mt-10 m-6">
        <div className="flex relative rounded-xl overflow-hidden shadow-blue-shadow mx-auto" style={{height: "400px", maxWidth: "1440px"}}>
            <img src={FooterUrl} className="inset-0 absolute h-full w-full object-cover object-center-bottom z-10"/>
            <img src={FooterGradUrl} className="inset-0 absolute h-full w-full object-cover z-20"/>
            <div className="inset-0 aboslute h-full w-full z-30" style={{
                background: `rgba(103, 102, 161, 0.35)`
            }}/>
            <div className="inset-0 absolute h-full w-full z-30 flex flex-col justify-center items-center">
                <div className="font-bold text-white text-2xl md:text-5xl text-center">
                    <FormattedMessage id="Besuche unsere Community im Discord!" />
                </div>
                <div className="mt-6 text-center">
                    <a href={DISCORD_URL}><DiscordButton /></a>
                </div>
            </div>
        </div>
    </div>
}
