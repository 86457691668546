import { DefaultButton } from "./DefaultButton";
import { ReactComponent as DiscordLogo } from "./../../assets/images/discord.svg";
import { FormattedMessage } from "react-intl";
import { DISCORD_URL } from "../../config";

export function DiscordButton(props: Omit<Parameters<typeof DefaultButton>[0], 'children'>) {
    return <a href={DISCORD_URL}>
    <DefaultButton className="flex">
        <DiscordLogo className="w-6 h-6 mr-2" /> <FormattedMessage id={"Discord beitreten!"} />
    </DefaultButton>
    </a>
}
