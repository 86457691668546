import PlantUrl from "../../assets/images/plant.png";
import {FormattedMessage} from "react-intl";
import { OrangeButton } from "../buttons/OrangeButton";
import {useMintUrl} from "../../config";
import { ReactComponent as OpenSeaIcon } from "../../assets/opensea.svg";


export function MintNowSection() {
    const MINT_URL = useMintUrl();

    return <div>
        <div className="flex md:hidden relative rounded-xl overflow-hidden m-6" style={{height: "400px"}}>
            <img src={PlantUrl} className="inset-0 absolute h-full w-full object-cover object-left-bottom z-10"/>
            <div className="inset-0 aboslute h-full w-full z-20" style={{background: "linear-gradient(0deg, rgba(142, 91, 44, 0.6), rgba(142, 91, 44, 0.6))"}}/>
            <div className="inset-0 absolute h-full w-full z-30 flex flex-col justify-center items-center">
                <div className="font-bold text-white text-2xl">
                    <FormattedMessage id="Sichere dir jetzt dein NFT" />
                </div>
                <div className="mt-6">
                    <a href="https://opensea.io/collection/castle-heroes">
                        <OrangeButton>
                            <div className="flex">
                                <OpenSeaIcon className="opensea-icon w-6 h-6 mr-2" /> <FormattedMessage id={"Castle Heroes auf Open Sea"} />
                            </div>
                        </OrangeButton>
                    </a>
                </div>
            </div>
        </div>
        <div className="hidden md:flex relative rounded-xl overflow-hidden m-6 mx-auto w-full" style={{height: "600px", maxWidth: "1440px"}}>
            <img src={PlantUrl} className="inset-0 absolute h-full w-full object-cover object-left-bottom z-10"/>
            <div className="inset-0 aboslute h-full w-full z-20" style={{background: "linear-gradient(0deg, rgba(142, 91, 44, 0.6), rgba(142, 91, 44, 0.6))"}}/>
            <div className="inset-0 absolute h-full w-full z-30 flex flex-col justify-center items-center">
                <div className="font-bold text-white text-7xl text-center">
                    <FormattedMessage id="Sichere dir jetzt dein NFT" />
                </div>
                <div className="mt-6">
                    <a href="https://opensea.io/collection/castle-heroes">
                        <OrangeButton>
                            <div className="flex">
                                <OpenSeaIcon className="opensea-icon w-6 h-6 mr-2" /> <FormattedMessage id={"Castle Heroes auf Open Sea"} />
                            </div>
                        </OrangeButton>
                    </a>
                </div>
            </div>
        </div>
    </div>
}
