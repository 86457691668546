import React, { DependencyList, useCallback, useEffect } from "react";


export function useOnBlur(element: React.MutableRefObject<HTMLElement|null>, action: ()=>void, actionDeps?: DependencyList, ignore?: React.MutableRefObject<HTMLElement|null>[]) {
    const act = useCallback(()=>action(), actionDeps??[]);
    useEffect(()=>{
        const ev = (e: MouseEvent) => {
            if (!element.current) return;
            let el: HTMLElement = e.target as HTMLElement;
            const ignoreArr = (ignore||[]).map(v=>v.current);
            while (el) {
                if (el === element.current || ignoreArr.includes(el)) return;
                el = el.parentElement as HTMLElement;
            }
            act();
        };
        window.addEventListener("click", ev);
        return () => window.removeEventListener("click", ev);
    }, [act]);
}