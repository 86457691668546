import { FormattedMessage } from "react-intl";
import { DISCORD_URL, OPENSEA_LEARN_URL, COINBASE_URL, METAMASK_URL } from "../../config";
import { DefaultButton } from "../buttons/DefaultButton";
import { Popup } from "../common/Popup";


export function HowToPopup(props: {onClose: ()=>void}) {
    return <Popup width="760px">
        <div className="text-dark font-bold text-3xl text-center">
            <FormattedMessage id="NFTs für Einsteiger" />
        </div>
        <div className="font-sofia-pro text-light-dark mt-2 text-center text-sm">
            <FormattedMessage id={"NFT steht für «Non-fungible Token», also ein nicht austauschbarer Token auf einer Blockchain. Die meisten NFTs sind digitale Sammlerstücke, Musik, Kunstwerke oder Ingame-Gegenstände. Um einen NFT zu erwerben, benötigst du zuerst ein Ethereum Blockchain kompatibles Wallet mit etwas Guthaben, welches du für den Transfer benötigst. Wir haben dir eine einfache Schritt-für-Schritt-Anleitung erstellt."} />
        </div>
        <div className="font-sofia-pro text-light-dark mt-2 text-center text-sm">
            <FormattedMessage id={"Wenn du Fragen hast, besuche uns einfach auf {discord_link}. Das ist kein Forum zur Konflikt-Bewältigung, sondern eine renommierte Kommunikations-App. Dort helfen wir dir gemeinsam mit der Castle Heroes Community gerne weiter. Wenn du mehr über Non-fungible Tokens erfahren möchtest, findest du auf der Plattform Opensea einen {opensea_link}"}
            values={{
                "discord_link": <a href={DISCORD_URL} className="text-active font-bold underline">Discord</a>,
                "opensea_link": <a href={OPENSEA_LEARN_URL} className="text-active font-bold underline"><FormattedMessage id={"detaillierten Reiseführer durch die Welt der NFTs."} /></a>
            }}
            />
        </div>

        <div className="flex gap-3 text-dark text-headline-3 mt-6">
            <span>1</span>
            <FormattedMessage id="Krypto-Wallet herunterladen und installieren" />
        </div>
        <div className="font-sofia-pro mt-2 text-light-dark text-sm">
            <FormattedMessage id={"Besorge dir ein Ethereum-kompatibles Crypto-Wallet. Dafür empfehlen wir {metamask_link}: Es ist kostenlos und du kannst es deinem Browser hinzufügen. Nach der Installation erhältst du von MetaMask eine eindeutige Wallet-Adresse."}
            values={{
                "metamask_link": <a href={METAMASK_URL} className="text-active font-bold underline">MetaMask</a>
            }}
            />
        </div>

        <div className="flex gap-3 text-dark text-headline-3 mt-6">
            <span>2</span>
            <FormattedMessage id="Fülle dein Wallet" />
        </div>
        <div className="font-sofia-pro mt-2 text-light-dark text-sm">
            <FormattedMessage id={"Da bei jedem Transfer Gebühren zwischen 8 und 10 Franken anfallen, ist etwas Guthaben im Wallet ratsam. Einige Wallets erlauben es dir, ETH direkt mit deiner Kreditkarte zu erwerben. Alternativ kannst du dir auch ETH auf {coinbase_link} kaufen und an deine Wallet-Adresse überweisen. Sobald du etwas Guthaben auf deinem Wallet hast, steht dem Minten deines Castle Heroes nichts mehr im Weg."}
            values={{
                "coinbase_link": <a href={COINBASE_URL} className="text-active font-bold underline">Coinbase</a>
            }}
            />
        </div>

        <div className="flex gap-3 text-dark text-headline-3 mt-6">
            <span>3</span>
            <FormattedMessage id="NFT kaufen" />
        </div>
        <div className="font-sofia-pro mt-2 text-light-dark text-sm">
            <FormattedMessage id={"Auf unserer Seite sind leider keine Castle Heroes mehr verfügbar. Was aber nicht heisst, dass du keinen mehr kriegst! Über NFT-Marktplätze wie beispielsweise Opensea, kannst du NFTs kaufen und verkaufen. Befolge folgende Schritte:"} />
            <br></br>
            <br></br>
            <FormattedMessage id={"Schritt 1: Suche auf Opensea einen NFT aus. Alle Castle Heroes, die zum Verkauf stehen, findest du hier:"} 
            values={{
                "link": <a href={"https://opensea.io/collection/castle-heroes"} className="text-active font-bold underline">https://opensea.io/collection/castle-heroes</a>
            }}/>
            <br></br>
            <FormattedMessage id={"Schritt 2: Installiere dir eine Wallet für den Kauf des NFTs."} />
            <br></br>
            <FormattedMessage id={"Schritt 3: Besorge dir die Kryptowährungen, mit der du das jeweilige NFT kaufen kannst. Der Grossteil der NFTs ist auf der Blockchain von Ethereum gespeichert."} />
            <br></br>
            <FormattedMessage id={"Schritt 4: Kaufe den NFT"} />
        </div>

        <div className="flex gap-3 text-dark text-headline-3 mt-6">
            <FormattedMessage id="Und nun?" />
        </div>
        <div className="font-sofia-pro mt-2 text-light-dark text-sm font-bold">
            <FormattedMessage id={"Sobald du deinen NFT gekauft hast, gehört er dir – allerdings kann es 5-15 Minuten dauern bis die Blockchain den Kauf verzeichnet und du den Castle Hero in deinem Wallet oder auf deinem Opensea-Account siehst"} />
        </div>

        <div className="mt-6 flex justify-center">
            <DefaultButton className="w-72" onClick={()=>props.onClose()}>
                <FormattedMessage id="Alles klar!" />
            </DefaultButton>
        </div>
    </Popup>
}