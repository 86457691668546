import { useState } from "react";
import { ReactComponent as PlusSvg } from "../../assets/icons/plus-circle.svg";
import { FormattedMessage } from "react-intl";
import {DISCORD_URL} from "../../config";

interface FaqItem {
    header: string;
    content: string;
}

const faqItems: FaqItem[] = [
    {
        header: "Sind die NFTs limitiert erhältlich?",
        content: "Ja! Die Helden sind seit 1876 im Schloss – und genauso viele haben sich als NFT zur Verfügung gestellt.",
    },
    {
        header: "Was kostet ein NFT?",
        content: "Wie auf allen Marktplätzen, kann der Verkäufer einen Festpreis für das NFT bestimmen oder eine Auktion starten. Um den jeweiligen NFT zu erwerben, können Interessenten den angezeigten Preis zahlen \"Buy\" oder über \"Make offer\" ihr Angebot einstellen. Beim Kauf fallen Verkaufsgebühren (2,5%), potentielle Gas-Fees und Lizenzgebühren an."
    },
    {
        header: "Was ist Opensea?",
        content: "OpenSea ist der erste und größte Peer-to-Peer-Marktplatz für Krypto-Waren (ein Äquivalent zu eBay für Krypto-Assets), der Sammlerstücke und nicht fungible Token umfasst, die durch eine Blockchain gesichert sind. Auf OpenSea kann jeder diese Artikel über einen Smart Contract kaufen oder verkaufen. Darüber hinaus sind die OpenSea-Gebühren niedrig – Der Deal ist folgender: OpenSea stellt die Infrastruktur des NFT-Marktplatzes kostenlos zur Verfügung, und die Benutzer decken den Unterhalt der Plattform mit einer Gebühr von 2.5 % auf jeden NFT-Verkauf ab.",
    },
    {
        header: "Wie kann ich mein Wallet mit dem justDrink-Account verknüpfen?",
        content: "Registriere oder melde dich bei justDrink an. Unter Mein Konto kannst du dein Krypto-Wallet verknüpfen. Sobald dein Castle Hero verknüpft ist, profitierst du von deinem Rabatt und den exklusiven Angeboten. Der Rabatt ist im Warenkorb ersichtlich.",
    },
    {
        header: "Welche Blockchain verwendet ihr?",
        content: "Die Castle Heroes haben ihr zu Hause auf der Ethereum Blockchain. Wertstabil und Krisenfest.",
    },
    {
        header: "Sind alle Castle Heroes gleich?",
        content: "Nein! Jeder Castle Hero ist einzigartig und wird zufällig vergeben. Aber alle haben die selben tollen Benefits gemeinsam."
    },
    {
        header: "Wo erhalte ich weitere Informationen?",
        content: "In unserem {communityDiscord}. Dort triffst du online auf viele weitere Castle-Hero-Holder und erhältst jede Menge weitere Informationen sowie exklusive Ankündigungen für die Community.",
    },
];

export function FaqSection() {
    const [activeItem, setActiveItem] = useState<string|null>(null);
    
    const onClick = (item: FaqItem) => setActiveItem(activeItem => activeItem!==item.header?item.header:null);

    return <div className="p-6 md:p-0 max-w-full md:mt-6 m-auto" style={{maxWidth: "1440px"}}>
        <div className="border border-stroke-light rounded-2xl font-sofia-pro p-10">
            {faqItems.map((item, i) => <div
                key={i}
                className={`flex border-stroke-light gap-5 py-5 select-none ${i!==faqItems.length-1?'border-b':''}`}
            >
                <div>
                    <PlusSvg className={`cursor-pointer w-5 h-5 mt-1 transition-transform ${activeItem === item.header ? '-rotate-45' : ''}`}
                        onClick={() => onClick(item)}
                    />
                </div>
                <div>
                    <div className="text-dark font-bold text-lg cursor-pointer" onClick={() => onClick(item)}>
                        <FormattedMessage id={item.header} />
                    </div>
                    <div className={`text-light-dark text-0 transition-all ${activeItem === item.header ? 'mt-5' : 'mt-0'}`}
                        style={{
                            fontSize: `${activeItem === item.header ? '16px' : '0px'}`
                        }}
                    >
                        <FormattedMessage id={item.content}
                                          values={{
                                              communityDiscord: <a href={DISCORD_URL} className="text-active font-bold underline">
                                                  <FormattedMessage id={"Community-Discord"} />
                                              </a>
                                          }}
                        />
                    </div>
                </div>
            </div>)}
        </div>
    </div>;
}