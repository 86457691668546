import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { CommonContext } from "../..";
import { ReactComponent as FlagDe } from "../../assets/icons/flag-de.svg";
import { ReactComponent as FlagFr } from "../../assets/icons/flag-fr.svg";
import { ReactComponent as FlagIt } from "../../assets/icons/flag-it.svg";
import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down.svg";
import { Language } from "../../app/translation";
import { useOnBlur } from "../../app/utils";

export function LanguageSelector() {
    const commonContext = useContext(CommonContext);
    const {language, setLanguage} = commonContext;
    const [isSelectorOpen, setSelectorOpen] = useState<boolean>(false);
    const menuRef = useRef<HTMLDivElement|null>(null);

    const languagesIcons: Record<string, React.FunctionComponent<React.SVGProps<SVGSVGElement>>> = {
        "de": FlagDe,
        "fr": FlagFr,
        "it": FlagIt
    };
    const LanguageIcon = languagesIcons[language];

    useOnBlur(menuRef, ()=>setSelectorOpen(false));

    console.log(`isSelectorOpen = ${isSelectorOpen}`);

    return <div className="relative select-none" ref={menuRef}>
        <div className="flex gap-3 h-10 items-center cursor-pointer" onClick={()=>setSelectorOpen(!isSelectorOpen)}>
            <LanguageIcon className="w-7" />
            <div className="uppercase font-bold text-light-dark">{language}</div>
            <ChevronDown className="w-3"/>
        </div>
        {isSelectorOpen&&<div className="absolute top-10 -translate-x-1/2 left-1/2" style={{zIndex: 9999}}>
            <div className="bg-white shadow-darker-shadow p-3 rounded">
                {Object.keys(languagesIcons).map(lang => {
                    const Icon = languagesIcons[lang];
                    return <div key={lang} className="flex gap-3 items-center cursor-pointer px-5 h-10 hover:bg-slate-300" 
                        onClick={()=>{setLanguage(lang as Language);setSelectorOpen(false)}}>
                        <Icon className="w-7"/>
                        <div className="uppercase font-bold text-light-dark">{lang}</div>
                    </div>
                })}
            </div>
        </div>}
    </div>;
}
