import {useContext, useEffect} from "react";
import {ReactElement, ReactNode} from "react";
import {IntlProvider} from "react-intl";
import { CommonContext } from "..";
import translations from "./translations.json";

export interface TranslationProviderProps {
    children: ReactNode;
}

export const DEFAULT_LANGUAGE = 'de';
export const LANGUAGES = ['de', 'fr', 'it'] as const;
export type Language = (typeof LANGUAGES)[number];

type Translations = Record<string, string>;

const translationsMap: Record<Language, Translations> = {
    'de': getTranslations('de'),
    'fr': getTranslations('fr'),
    'it': getTranslations('it'),
}

export function getTranslations(language: Language): Translations  {
    return Object.keys(translations).reduce(
        (acc, key) => ({...acc, [key]: (translations as any)[key][language]}),
        {} as Translations
    );
}

export function getInitialLanguage(): Language {
    const url = new URL(window.location.href);
    return (url.searchParams?.get("lang")
        || localStorage.getItem("lang")
        || DEFAULT_LANGUAGE) as Language;
}

export function TranslationProvider(props: TranslationProviderProps): ReactElement {
    const commonContext = useContext(CommonContext);
    const language = commonContext.language;

    useEffect(()=>{
        localStorage.setItem("lang", commonContext.language);
    }, [commonContext.language]);

    return <IntlProvider locale={`${language}-CH`} messages={translationsMap[language]}>
        {props.children}
    </IntlProvider>;
}

export function useLanguage(): Language {
    const commonContext = useContext(CommonContext);
    return commonContext.language;
}
