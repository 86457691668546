import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import {DEFAULT_LANGUAGE, getInitialLanguage, Language, TranslationProvider} from './app/translation';
import "./index.css";

// eslint-disable-next-line
const consoleError = console.error.bind(console);
// eslint-disable-next-line
console.error = (message, ...args) => {
  if (
    typeof message === 'string' &&
    message.startsWith('[React Intl] Missing message:')
  ) {
    return;
  }
  consoleError(message, ...args);
};

export const CommonContext = React.createContext({} as {
  language: Language,
  setLanguage: (lang: Language) => void
});

const Wrapper = () => {
  const [language, setLanguage] = useState<Language>(getInitialLanguage());

  return <CommonContext.Provider value={{language, setLanguage}}>
    <TranslationProvider>
      <App />
    </TranslationProvider>
  </CommonContext.Provider>
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Wrapper />
  </React.StrictMode>
);